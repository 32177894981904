.main {
  width: 100%;
  height: 100vh;
}

img {
  width: 600px;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 100px;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 50px;
}

.link {
  margin: 10px;
}

.links a {
  color: white;
}

.links a:visited {
  color: white;
}

.link:hover {
  color: aqua !important;
}

/* ======== MEDIA QUERIES ======== */
@media screen and (max-width: 1024px) {
  img {
    width: 400px;
  }
  .links a {
    font-size: 40px;
    color: white;
  }
  .links a:visited {
    color: white;
  }
  .link {
    margin: 5px;
  }
}

@media screen and (max-width: 600px) {
  img {
    width: 300px;
  }
  .links a {
    font-size: 30px;
    color: white;
  }
  .links a:visited {
    color: white;
  }
  .link {
    margin: 5px;
  }
}
